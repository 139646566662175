import React, { useState, useEffect, useRef } from "react";
import logo from '../../assets/logo/logo-horizontal.svg';
import styles from './Header.module.css';
import useWindowDimensions from "../../Services/WindowsDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser, faRotateRight, faTimes, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'universal-cookie';
// import AddItemTemplate from "../Shopping/Basket/AddItem/AddItemTemplate";
import ItemBasketChoice from "../Shopping/Basket/AddItem/ItemBasketChoice";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mascottes from '../../assets/mascottes/ato_rika_tristes.svg'

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_BASKET_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);

// fonction au clique du choix du panier back

function backChoice(formatedBasket) {
    cookies.set('basketQuantity', JSON.stringify(formatedBasket), cookieOptions);
    cookies.set('lastchanger', "client", cookieOptions);
    window.location.href = '/basket';
}

// fonction au clique du choix du panier cookie

async function cookieChoice(basketQuantityCookie) {

    let requestRemove = new Request(process.env.REACT_APP_API_URL + '/client/basket/empty', { method: 'POST', credentials: 'include' })
    let response = await fetch(requestRemove);
    let data = await response.json();
    if (data) {
        if (data["success"]) {
            let jsonD = new FormData();
            jsonD.append("json", JSON.stringify(basketQuantityCookie));
            let request2 = new Request(process.env.REACT_APP_API_URL + '/client/basket/create', { method: 'POST', credentials: 'include', body: jsonD });
            fetch(request2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();

            })
            window.location.href = '/basket';
        }
    }
}

function showItemBasket(dataBasketarticleCookie, quantity) {
    let items = []

    if (dataBasketarticleCookie && dataBasketarticleCookie.type !== "NONE") {
        for (let i = 0; i < dataBasketarticleCookie.added.length; i++) {
            let article = dataBasketarticleCookie.added[i];

            let infos = [];
            if (article.isCommitment) {
                infos.push("Durée : " + article.duration + " mois")
            }
            infos.push(
                article.description
            )

            items.push(
                <ItemBasketChoice
                    key={'item' + i}
                    name={article.name}
                    media={article.media.length > 0 ? article.media[0] : undefined}
                    // infos={infos}
                    priceTtc={article.priceTtc / 100}
                    unitPriceTtc={article.unitPriceTtc}
                    quantity={quantity[article.reference]}
                    theme={article.theme}
                    secondaryTheme={article.secondaryTheme}
                    stock={article.stock}
                    isComm={article.isCommitment}
                    choosen={article.choosen}
                    duration={article.duration}
                    lastItem={dataBasketarticleCookie.added.length - 1 === i ? true : false}
                />
            )
        }
        return items
    }
}

// Fonction pour comparer deux objets sans tenir compte de l'ordre des clés
function deepEqual(obj1, obj2) {
    // Vérifie si les deux objets ont le même nombre de clés

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    // Vérifie si les valeurs pour chaque clé sont les mêmes dans les deux objets
    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}

function Header(props) {

    const [ShowPopUp, setShowPopUp] = useState(false);
    const [dataBasketBack, setDataBasketBack] = useState(null);
    const [dataBasketarticleCookie, setDataBasketarticleCookie] = useState(null);
    const [dataBasketarticleBack, setdataBasketarticleBack] = useState(null);
    const [dataBasketBackEnd, setdataBasketBackEnd] = useState(false);
    const [basketQuantityCookie, setBasketQuantityCookie] = useState({});
    const [formatedBasket, setFormatedBasket] = useState({});
    const [totalBasket, setTotalBasket] = useState(0);
    const [isMobile, setIsMobile] = useState(true);

    const [disabledBasketHover, setDisabledBasketHover] = useState(false);
    const containerArticleRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState('auto');
    const [previousCookieValue, setPreviousCookieValue] = useState(cookies.get('basketQuantity'));

    function outerHeight(el) {
        var height = el.offsetHeight;
        var style = getComputedStyle(el);

        height += parseInt(style.marginBottom);
        return height;
    }

    useEffect(() => {
        const container = containerArticleRef.current;
        if (container) {
            const children = Array.from(container.children);
            if (children.length > 2) {
                setMaxHeight(`${outerHeight(children[0]) + outerHeight(children[1]) + outerHeight(children[2]) / 2}px`);
            } else {
                setMaxHeight('auto');
            }
        }
    }, [dataBasketarticleCookie?.added]);


    useEffect(() => {
        setIsMobile(window.innerWidth <= 768)
        let currentUrl = window.location.href;
        if (currentUrl.includes("basket") || currentUrl.includes("delivery") || currentUrl.includes("payment")) {
            setDisabledBasketHover(true);
        } else {
            setDisabledBasketHover(false);
        }

    }, []);

    useEffect(() => {
        const basketArticle = document.querySelector(`.${styles.basketArticle}`);
        if ((!cookies.get('basketQuantity') || totalBasket === 0)) {
            basketArticle.classList.add("emptyBasket");
        }
        else {
            basketArticle.classList.remove("emptyBasket");
        }
    })

    /* GESTION HOVER PANIER --------------------------- */

    const handleMouseEnter = () => {
        if (!isMobile) {
            document.querySelector(`.${styles.basketArticle}`).classList.add("visible");
            const overlayBasket = document.querySelector(`.${styles.overlayBasket}`);
            overlayBasket.classList.add('visible');
        }
    };

    const ClickMobileBasket = () => {
        const basketArticle = document.querySelector(`.${styles.basketArticle}`);
        const overlayBasket = document.querySelector(`.${styles.overlayBasket}`);

        basketArticle.classList.toggle('visible');
        overlayBasket.classList.toggle('visible');
    };

    const closeOverlayAndBasket = () => {
        document.querySelector(`.${styles.basketArticle}`).classList.remove("visible");

        const overlayBasket = document.querySelector(`.${styles.overlayBasket}`);
        if (overlayBasket) {
            overlayBasket.classList.remove('visible');
        }
    }

    useEffect(() => {
        const cookieBasket = cookies.get('basketQuantity');
        if (cookieBasket) {
            let refs = Object.keys(cookieBasket);
            let jsonD = new FormData();
            jsonD.append("json", JSON.stringify(refs));
            let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/items', { method: 'POST', credentials: 'include', body: jsonD });
            fetch(request)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    const dataBasketarticleCookieFromBack = data;
                    setDataBasketarticleCookie(dataBasketarticleCookieFromBack);
                })
        }
    }, [basketQuantityCookie])



    const showArticleBasket = (item, index) => {
        // retirer tous les accents + mettre en minucule + remplacer espace par tiret + mettre ref à la fin
        var slug
        if (!item.choosen) {
            slug = item?.name?.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .replaceAll(/\s/g, "-") + "-" + item.reference;
        } else {
            slug = item?.choosen?.name?.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .replaceAll(/\s/g, "-") + "-" + item.choosen.reference;
        }
        return (
            <div key={index} className={styles.containerItemBasket}>
                <img alt="" className={styles.imgBasket} src={process.env.REACT_APP_CDN_URL + "/uploads/article/" + item.media[0]}></img>
                <div className={styles.containerNomQty}>


                    {item?.choosen
                        ?

                        <a href={process.env.REACT_APP_SHOP_URL + '/produit/' + slug}>
                            {item.name} - {item.duration} mois - {item.choosen.name}
                        </a>
                        :
                        <a href={process.env.REACT_APP_SHOP_URL + '/produit/' + slug} key={index}>{item.name}</a>
                    }
                    {!item.isCommitment ?
                        <select
                            className={styles.selectQuantite}
                            value={basketQuantityCookie[item.reference]}
                            onChange={(e) => {
                                const quantityProduct = parseInt(e.target.value);
                                const newProduct = item.choosen ? item.reference + "-" + item.choosen.reference : item.reference
                                updateQuantity(newProduct, quantityProduct)
                            }}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>

                            {basketQuantityCookie[item.reference] < 8 &&
                                <>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </>
                            }
                            {basketQuantityCookie[item.reference] >= 8 &&
                                <>
                                    <option value={basketQuantityCookie[item.reference] - 2}>{basketQuantityCookie[item.reference] - 2}</option>
                                    <option value={basketQuantityCookie[item.reference] - 1}>{basketQuantityCookie[item.reference] - 1}</option>
                                    <option value={basketQuantityCookie[item.reference]}>{basketQuantityCookie[item.reference]}</option>
                                    <option value={basketQuantityCookie[item.reference] + 1}>{basketQuantityCookie[item.reference] + 1}</option>
                                    <option value={basketQuantityCookie[item.reference] + 2}>{basketQuantityCookie[item.reference] + 2}</option>

                                    <option value={Math.floor((basketQuantityCookie[item.reference] + 7) / 5) * 5}>{Math.floor((basketQuantityCookie[item.reference] + 7) / 5) * 5}</option>
                                </>
                            }
                        </select>
                        : ""}
                </div>

                <div className={styles.containerPrixBasket}>
                    <div className={styles.TrashCan} onClick={(e) => {
                        e.stopPropagation();
                        const refProduct = item.choosen ? item.reference + "-" + item.choosen.reference : item.reference
                        removeProduct(index, refProduct)
                    }}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </div>
                    <div className={styles.price}>{formatPrice(item.priceTtc)}</div>
                </div>
            </div>
        )
    }

    function formatPrice(price) {
        let priceStr = price.toString();

        let euros = priceStr.slice(0, -2);
        let cents = priceStr.slice(-2);

        // Retourner le prix formaté avec le symbole "€"
        return (
            <>
                <div className={styles.euro}>{euros}</div>
                <div className={styles.centime}>€{cents}</div>
            </>
        );
    }

    const removeProduct = (index, refProduct) => {
        // Copiez l'objet pour éviter des mutations directes
        const updatedQuantity = { ...basketQuantityCookie };

        // Décrémentez la quantité et supprimez-la si elle atteint 0
        const quantityToRemove = basketQuantityCookie[refProduct];
        if (quantityToRemove) {
            updatedQuantity[refProduct] -= quantityToRemove;
            if (updatedQuantity[refProduct] <= 0) {
                delete updatedQuantity[refProduct];
            }
        }

        // Vérifiez si l'utilisateur est connecté avant de faire la requête
        if (cookies.get('connected')) {
            let jsonProduct = new FormData();
            jsonProduct.append("json", JSON.stringify([refProduct]));
            let requestRemove = new Request(process.env.REACT_APP_API_URL + '/client/basket/removeProducts', {
                method: 'POST',
                credentials: 'include',
                body: jsonProduct
            });
            fetch(requestRemove);
        }

        // Mettez à jour le cookie et l'état
        setBasketQuantityCookie(updatedQuantity);
        cookies.set('basketQuantity', JSON.stringify(updatedQuantity), cookieOptions);
        cookies.set('lastchanger', "client", cookieOptions);

        // Filtrez l'article à supprimer en utilisant l'index et mettez à jour l'état
        setDataBasketarticleCookie((prevData) => {
            return {
                ...prevData,
                added: prevData.added.filter((_, i) => i !== index),
            };
        });
    };


    const updateQuantity = (newProduct, quantityProduct) => {
        // Créez une nouvelle copie de l'objet pour éviter les mutations directes
        const updatedBasketQuantityCookie = { ...basketQuantityCookie, [newProduct]: quantityProduct };

        // Mettez à jour le cookie et l'état
        cookies.set('basketQuantity', JSON.stringify(updatedBasketQuantityCookie), cookieOptions);
        cookies.set('lastchanger', "client", cookieOptions);
        setBasketQuantityCookie(updatedBasketQuantityCookie); // Utilisez la copie mise à jour

        // Vérifiez si l'utilisateur est connecté et faites la requête fetch
        if (cookies.get('connected')) {
            let jsonQuantity = new FormData();
            jsonQuantity.append("json", JSON.stringify([newProduct, quantityProduct]));

            let requestQuantity = new Request(process.env.REACT_APP_API_URL + '/client/basket/updateQuantity', { method: 'POST', credentials: 'include', body: jsonQuantity });
            fetch(requestQuantity);
        }
    };


    /* FIN GESTION HOVER PANIER ---------------------------*/

    useEffect(() => {
        // récupère les data des article du panier du back
        let cancelled = false;
        async function fetchData() {
            try {
                let request = new Request(process.env.REACT_APP_API_URL + '/client/basket', { method: 'GET', credentials: 'include' });
                let response = await fetch(request);
                if (cancelled) {
                    return;
                }
                let data = await response.json();
                if (data) {
                    setDataBasketBack(data);
                    setdataBasketBackEnd(true);
                }
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération du panier:', error);
            }
        }

        if (cookies.get('connected')) {
            fetchData();
            let basketQuantity = cookies.get('basketQuantity');
            let formatedBasket = basketQuantity ? basketQuantity : {};
            dataBasketBack?.articles && dataBasketBack.articles.forEach(item => {
                if (item.fromDependency && !formatedBasket[item.article.reference]) {
                    // Ajouter l'article au panier formaté
                    formatedBasket[item.article.reference] = item.quantity;
                }
            });
            if (JSON.stringify(formatedBasket) !== JSON.stringify(basketQuantity)) {
                cookies.set('basketQuantity', JSON.stringify(formatedBasket), cookieOptions);
                cookies.set('lastchanger', "client", cookieOptions);
            }
        }
        // Récupère le cookie une fois mis a jour si produit supplémentaire
        const basketQuantityCookieValue = cookies.get('basketQuantity');
        if (basketQuantityCookieValue && Object.keys(basketQuantityCookieValue).length > 0) {
            setBasketQuantityCookie(basketQuantityCookieValue);
        } else {
            setBasketQuantityCookie({ "0": 0 });
        }

        return () => {
            cancelled = true;
        };
    }, [totalBasket]);

    useEffect(() => {

        var basketData = cookies.get("basketQuantity", cookieOptions);
        if (basketData) {
            setTotalBasket(Object.values(basketData).reduce((acc, qty) => acc + qty, 0));
        } else {
            cookies.set('basketQuantity', {}, cookieOptions);
            cookies.set('lastchanger', "client", cookieOptions);
        }
    }, [])

    useEffect(() => {
        // Vérifie le cookie toutes les secondes (ajuste l’intervalle si nécessaire)
        const intervalId = setInterval(() => {
            const currentCookieValue = cookies.get('basketQuantity');
            // Si le cookie a changé, mets à jour le panier et enregistre la nouvelle valeur
            if (JSON.stringify(currentCookieValue) !== JSON.stringify(previousCookieValue)) {
                setPreviousCookieValue(currentCookieValue);
                if (currentCookieValue !== "{}")
                    setTotalBasket(Object.values(currentCookieValue).reduce((acc, qty) => acc + qty, 0));
                let currentUrl = window.location.href;
                if (cookies.get('lastchanger') !== 'client' &&
                    ((currentUrl.includes("basket") || currentUrl.includes("delivery") || currentUrl.includes("payment")))) {
                        window.location.href = (cookies.get('connected') ? '/basket' : '/validatebasket')
                }
            }
        }, 1000); // Vérification toutes les secondes

        // Nettoie l'intervalle quand le composant est démonté
        return () => clearInterval(intervalId);
    }, [previousCookieValue]);

    useEffect(() => {
        if (dataBasketBack && cookies.get('connected')) {

            // je formate les data des produit du back sous la meme forme que le cookie

            if (dataBasketBack.articles && dataBasketBack.articles.length > 0) {
                let myBasket = {}
                dataBasketBack?.articles && dataBasketBack.articles.forEach(article => {
                    let reference = article.article.reference;
                    if (article.article.isCommitment) {
                        reference += '-' + article?.firstBox?.article?.reference;
                    }
                    if (myBasket[reference]) {
                        myBasket[reference] = myBasket[reference] + article.quantity
                    } else {
                        myBasket[reference] = article.quantity;
                    }
                });

                setFormatedBasket(myBasket)
            } else {
                setFormatedBasket({ "0": 0 });
            }
        }
    }, [dataBasketBackEnd]);


    useEffect(() => {
        // je vérifie si le cookie ou le panier back est vide , sinon je laisse le choix du panier au client
        if (!deepEqual(basketQuantityCookie, formatedBasket)) { //Regarde si le cookie et le back ont pas le meme contenu
            if (JSON.stringify(basketQuantityCookie) === JSON.stringify({ "0": 0 })) { // cookie est null
                cookies.set('basketQuantity', JSON.stringify(formatedBasket), cookieOptions);
                cookies.set('lastchanger', "client", cookieOptions);
            } else if ((JSON.stringify(formatedBasket) === JSON.stringify({ "0": 0 }) || Object.keys(formatedBasket).length === 0)) { // back est null
                // let refs = Object.keys(basketQuantityCookie);
                let jsonD = new FormData();
                jsonD.append("json", JSON.stringify(basketQuantityCookie));
                let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/create', { method: 'POST', credentials: 'include', body: jsonD });
                fetch(request).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                    .then(data => {
                        if (dataBasketBack.length !== 0) {
                            dataBasketBack?.articles?.forEach((article) => {
                                let reference = article.article.reference;
                                if (article.article.isCommitment) {
                                    reference += '-' + article?.firstBox?.article?.reference;
                                } else {
                                    data.ref[reference] = article.quantity
                                }
                            });
                        }
                        if (!deepEqual(data.ref, basketQuantityCookie)) { // si retour back pas égale au cookie
                            cookies.set('basketQuantity', JSON.stringify(data.ref), cookieOptions);
                            cookies.set('lastchanger', "client", cookieOptions);
                        }
                    })
                    .catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            } else { // cookie et back différents et remplis
                // je récupère les data des articles du cookie
                let refs = Object.keys(basketQuantityCookie);
                let jsonD = new FormData();
                jsonD.append("json", JSON.stringify(refs));
                let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/items', { method: 'POST', credentials: 'include', body: jsonD });
                let promise1 = fetch(request)
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        const dataBasketarticleCookie = data;
                        setDataBasketarticleCookie(dataBasketarticleCookie);
                    })

                // je récupère les data des articles du back

                let refsBack = Object.keys(formatedBasket);
                let jsonBack = new FormData();
                jsonBack.append("json", JSON.stringify(refsBack));
                let requestBack = new Request(process.env.REACT_APP_API_URL + '/client/basket/items', { method: 'POST', credentials: 'include', body: jsonBack });
                let promise2 = fetch(requestBack)
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        const dataBasketarticleBack = data;
                        setdataBasketarticleBack(dataBasketarticleBack);
                    })

                // j'affiche la popup quand j'ai toutes les datas du back et cookie

                Promise.all([promise1, promise2])
                    .then(values => {
                        setShowPopUp(true);
                    })
            }
            //}
        }
    }, [formatedBasket]);


    const { width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(false);
    const [showMenuConnected, setShowMenuConnected] = useState(false);
    const [isPanier1Open, setIsPanier1Open] = useState(isMobile ? false : true);
    const [isPanier2Open, setIsPanier2Open] = useState(isMobile ? false : true);


    const [selectedPanier, setSelectedPanier] = useState('panier1');

    const handleValidation = () => {
        if (selectedPanier === 'panier1') {
            setDataBasketBack(null);
            cookieChoice(basketQuantityCookie);
        } else if (selectedPanier === 'panier2') {
            backChoice(formatedBasket);
        }
        setShowPopUp(false);
    };

    useEffect(() => {
        if (ShowPopUp) {
            const dialogBox = document.getElementsByClassName(styles.dialogBox)[0];
            dialogBox.style.transform = `translateY(0%)`;
        }
    })



    useEffect(() => {

        // Fonction pour ajuster la hauteur du conteneur en fonction des enfants
        const adjustContainerHeight = (container, isPanierOpen) => {
            if (container && width < 960) {
                if (isPanierOpen && container.children.length > 0) {
                    // Calculer la hauteur totale en additionnant la hauteur de chaque enfant
                    // Ajouter 20 pixels par enfant pour la marge
                    const totalHeight = Array.from(container.children).reduce((acc, child) => acc + child.offsetHeight, 0);
                    const totalHeightWithMargin = totalHeight + (container.children.length - 1) * 20; // + 20 pour le margin bottom de chaque enfant
                    container.style.height = `${totalHeightWithMargin}px`;
                } else {
                    container.style.height = '0px';
                }
            }
        };

        // Gestion du Panier 1
        const containerPanier1 = document.getElementsByClassName(styles.containerOneChoice)[0];
        adjustContainerHeight(containerPanier1, isPanier1Open);

        // Gestion du Panier 2
        const containerPanier2 = document.getElementsByClassName(styles.containerOneChoice)[1];
        adjustContainerHeight(containerPanier2, isPanier2Open);

    }, [isPanier1Open, isPanier2Open]);


    const basketQuantityCookieValue = cookies.get('basketQuantity');

    const clickOnBasket = (panier) => {
        const containerPanier2 = document.getElementsByClassName(styles.ContainerPanier)[1];
        const containerPanier1 = document.getElementsByClassName(styles.ContainerPanier)[0];

        if (panier === "panier1") {
            document.getElementById('panier2').checked = false
            document.getElementById('panier1').checked = true
            containerPanier1.style.border = `5px solid #6fa939`;
            containerPanier2.style.border = "5px solid #E4E4E4";
            setIsPanier2Open(false)
        } else {
            document.getElementById('panier1').checked = false
            document.getElementById('panier2').checked = true
            containerPanier1.style.border = `5px solid #E4E4E4`;
            containerPanier2.style.border = "5px solid #6fa939";
            setIsPanier1Open(false)
        }
    }

    useEffect(() => {
        if (ShowPopUp) { // Vérifier que le pop-up est affiché avant d'appliquer le style par défaut
            const containerPanier2 = document.getElementsByClassName(styles.ContainerPanier)[1];
            const containerPanier1 = document.getElementsByClassName(styles.ContainerPanier)[0];

            if (containerPanier1 && containerPanier2) {
                clickOnBasket('panier1');  // Appliquez le style de bordure par défaut pour panier1
            }
        }
    }, [ShowPopUp]);



    const basketResume = (articles, panier) => {

        if (articles) {

            const totalCents = articles.reduce((sum, article) => {

                const reference = article.reference;

                let quantity = 1;

                if (panier === "panier1") {
                    // Utilise la quantité du cookie
                    quantity = basketQuantityCookieValue[reference] || 1;
                } else if (panier === "panier2") {
                    // Utilise la quantité des données du back
                    quantity = formatedBasket[reference] || 1;
                }

                // Ajoute au total la somme prix TTC * quantité
                return sum + (article.priceTtc * quantity);
            }, 0);

            // Convertir en euros et centimes
            const totalEuros = Math.floor(totalCents / 100);
            const totalCentimes = totalCents % 100;

            const totalArticlesCookie = Object.values(basketQuantityCookieValue).reduce((total, qty) => total + qty, 0); // permet de prendre en compte la quantity popur le nombre d'article
            const totalArticlesBack = Object.values(formatedBasket).reduce((total, qty) => total + qty, 0); // permet de prendre en compte la quantity popur le nombre d'article

            return (
                <div className={styles.containerPrixArticle}>
                    {panier === "panier1" ? <div>{totalArticlesCookie} article{totalArticlesCookie > 1 ? "s" : ""}</div> : <div>{totalArticlesBack} article{totalArticlesBack > 1 ? "s" : ""}</div>}
                    <div className={styles.containerPrix}>
                        <div>{totalEuros}</div>
                        <div className={styles.centime}>€{totalCentimes}</div>
                    </div>
                    {width < 960 ? (
                        <FontAwesomeIcon
                            className={styles.arrow}
                            icon={faChevronRight}
                            style={{
                                alignSelf: "center",
                                transform: panier === "panier1" && isPanier1Open
                                    ? 'rotate(90deg)'
                                    : panier === "panier2" && isPanier2Open
                                        ? 'rotate(90deg)'
                                        : 'rotate(0deg)'
                            }}
                        />
                    ) : ""}
                </div>
            );
        }
    };

    if (width > 960) {
        return (
            <div>
                {ShowPopUp && (
                    <div className={styles.dialogContainer}>
                        <div className={styles.dialogBox}>
                            <div className={styles.containerquestion}>
                                <div className={styles.subTitleDialogBox} >Nous avons retrouvé d'autres articles que tu avais sélectionnés précédemment.</div>
                                <div className={styles.titleDialogBox} >Quel panier préfères-tu conserver ?</div>
                            </div>
                            <button className={styles.buttonChoice} onClick={handleValidation}> Valider mon choix</button>
                            <div className={styles.containerChoice} >
                                <div className={styles.ContainerPanier} onClick={() => [setSelectedPanier('panier1'), clickOnBasket('panier1')]}>

                                    <button className={styles.buttonPanier} onClick={() => { setIsPanier1Open(!isPanier1Open) }}>
                                        <input className={styles.radioBtn} type="radio" id="panier1" defaultChecked={'panier1'} name="panier" onChange={() => [setSelectedPanier('panier1'), clickOnBasket('panier1')]} />
                                        <label htmlFor="panier1" className={styles.checkboxLabel}>
                                            <span className={styles.customCheckbox}></span>
                                        </label>{basketResume(dataBasketarticleCookie.added, 'panier1')}
                                    </button>
                                    <div className={`${styles.containerOneChoice} ${dataBasketarticleCookie.added.length > 2 ? styles.whiteShadow : ""}`}>
                                        {showItemBasket(dataBasketarticleCookie, basketQuantityCookie)}
                                    </div>

                                </div>
                                <div className={styles.ContainerPanier} onClick={() => [setSelectedPanier('panier2'), clickOnBasket('panier2')]}>
                                    <button className={styles.buttonPanier} onClick={() => { setIsPanier2Open(!isPanier2Open) }}>

                                        <input className={styles.radioBtn} type="radio" id="panier2" name="panier" onChange={() => [setSelectedPanier('panier2'), clickOnBasket('panier2')]} />
                                        <label htmlFor="panier2" className={styles.checkboxLabel}>
                                            <span className={styles.customCheckbox}></span>
                                        </label>
                                        {basketResume(dataBasketarticleBack.added, 'panier2')}
                                    </button>
                                    <div className={styles.containerOneChoice}>
                                        {showItemBasket(dataBasketarticleBack, formatedBasket)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <header className={styles.navbar}>

                    <div className={styles.overlayBasket} onClick={closeOverlayAndBasket}></div>
                    <div className={styles.basketArticle} style={!isMobile ? { top: `75px` } : {}}>

                        {(!cookies.get('basketQuantity') || totalBasket === 0) ?
                            <>
                                <div>Hum, ton panier est vide !</div>
                                <span>Remplis-le un peu...</span>
                                <img alt="" src={mascottes} />
                            </>
                            :
                            (!dataBasketarticleCookie?.added || !dataBasketarticleCookie?.added?.length === 0) ?
                                <div className={styles.loader}><div></div></div>
                                :
                                <>
                                    <div ref={containerArticleRef} style={{ maxHeight: maxHeight }} className={styles.containerArticle}>
                                        {dataBasketarticleCookie?.added?.map((item, index) => {
                                            return showArticleBasket(item, index);
                                        })
                                        }
                                    </div>
                                    <div className={styles.containerButton}>
                                        <a className={styles.button} href={'/' + (props.connected ? '' : 'validate') + 'basket'}>Voir mon panier</a>
                                        <a className={`${styles.buttonRevers} ${styles.button}`} href={'/' + (props.connected ? '' : 'validate') + 'basket'}>Passer commande</a>
                                    </div>
                                </>
                        }

                    </div>

                    <div className={styles.logoContainer}>
                        <a href={process.env.REACT_APP_SHOP_URL}><img src={logo} alt="logo" className={styles.logo} /></a>
                    </div>

                    <a className={styles.link} href={process.env.REACT_APP_SHOP_URL}>
                        Boutique
                    </a>
                    <a className={styles.link} href={process.env.REACT_APP_HELP_URL}>
                        Besoin d'aide
                    </a>
                    <a className={styles.link} href={process.env.REACT_APP_SHOP_URL + "/contact"}>
                        Contact
                    </a>
                    <div className={styles.leftBtn} style={{ display: "flex", alignItems: 'center' }}>
                        {props.connected ?
                            <div className={styles.profileBtn} onClick={() => setShowMenuConnected(!showMenuConnected)}>
                                <FontAwesomeIcon className={styles.iconUser} icon={faUser} />
                                <span id='userName'> Bonjour {props.user.firstName} </span>
                            </div>
                            :
                            <div className={styles.profileBtn} onClick={(e) => { e.preventDefault(); window.location.href = '/login'; }}>
                                <FontAwesomeIcon className={styles.iconUser} icon={faUser} />
                                <span id='userName'> Se connecter </span>
                            </div>
                        }

                        {disabledBasketHover ?
                            <a className={styles.mobileUserBtn} href={'/' + (props.connected ? '' : 'validate') + 'basket'} style={{ position: "relative", fontSize: "1.8rem", margin: "0 20px 0 26px" }}>
                                <div style={{
                                    fontSize: "0.4em",
                                    position: "absolute",
                                    right: "-8px",
                                    top: `${totalBasket > 0 ? "1px" : "-100px"}`,
                                    backgroundColor: `${totalBasket > 0 ? "#571088" : "transparent"}`,
                                    color: "white",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px",
                                    zIndex: 100,
                                    fontWeight: 500
                                }}>
                                    {props.empty ? <FontAwesomeIcon className={styles.iconLoad} icon={faRotateRight} /> : totalBasket}
                                </div>
                                <div className={styles.basketShopping}></div>
                            </a>
                            :
                            <div className={styles.mobileUserBtn} onClick={ClickMobileBasket} onMouseEnter={handleMouseEnter} href={'/' + (props.connected ? '' : 'validate') + 'basket'} style={{ position: "relative", fontSize: "1.8rem", margin: "0 20px 0 26px" }}>
                                <div style={{
                                    fontSize: "0.4em",
                                    position: "absolute",
                                    right: "-8px",
                                    top: `${totalBasket > 0 ? "1px" : "-100px"}`,
                                    backgroundColor: `${totalBasket > 0 ? "#571088" : "transparent"}`,
                                    color: "white",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px",
                                    zIndex: 100,
                                    fontWeight: 500
                                }}>
                                    {props.empty ? <FontAwesomeIcon className={styles.iconLoad} icon={faRotateRight} /> : totalBasket}
                                </div>
                                <div className={styles.basketShopping}></div>
                            </div>
                        }
                    </div>


                </header>

                {props.connected && showMenuConnected &&

                    <div className={styles.dropdownMenuConnected}>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/">
                                Mon espace client
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/commands">
                                Mes commandes
                            </a>
                        </div>

                        {props.user.hasCommitment &&
                            <div className={styles.mobileMenuItem}>
                                <a className={styles.mobileLink} href="/commitment">
                                    Mes abonnements
                                </a>
                            </div>
                        }

                        {props.user.hasBox &&
                            <div className={styles.mobileMenuItem}>
                                <a className={styles.mobileLink} href="/box">
                                    Box et tutos
                                </a>
                            </div>
                        }


                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/stats">
                                Récompenses
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/logout">
                                Déconnexion
                            </a>
                        </div>

                    </div>
                }
            </div>
        );
    } else {
        return (
            <div>
                {ShowPopUp && (
                    <div className={styles.dialogContainer}>
                        <div className={styles.dialogBox}>
                            <div className={styles.containerquestion}>
                                <div className={styles.subTitleDialogBox} >Nous avons retrouvé d'autres articles que tu avais sélectionné précédemment.</div>
                                <div className={styles.titleDialogBox} >Quel panier préfères-tu conserver ?</div>
                            </div>
                            <button className={styles.buttonChoice} onClick={handleValidation}> Valider mon choix</button>
                            <div className={styles.containerChoice} >
                                <div className={styles.ContainerPanier} onClick={() => [setIsPanier1Open(!isPanier1Open), setSelectedPanier('panier1'), clickOnBasket('panier1')]}>

                                    <button className={styles.buttonPanier}>
                                        <input className={styles.radioBtn} type="radio" id="panier1" defaultChecked={'panier1'} name="panier" />
                                        <label htmlFor="panier1" className={styles.checkboxLabel}>
                                            <span className={styles.customCheckbox}></span>
                                        </label>{basketResume(dataBasketarticleCookie.added, 'panier1')}
                                    </button>
                                    <div className={styles.containerOneChoice}>
                                        {showItemBasket(dataBasketarticleCookie, basketQuantityCookie)}
                                    </div>

                                </div>
                                <div className={styles.ContainerPanier} onClick={() => [setIsPanier2Open(!isPanier2Open), setSelectedPanier('panier2'), clickOnBasket('panier2')]}>
                                    <button className={styles.buttonPanier}>
                                        <input className={styles.radioBtn} type="radio" id="panier2" name="panier" />
                                        <label htmlFor="panier2" className={styles.checkboxLabel}>
                                            <span className={styles.customCheckbox}></span>
                                        </label>
                                        {basketResume(dataBasketarticleBack.added, 'panier2')}
                                    </button>
                                    <div className={styles.containerOneChoice}>
                                        {showItemBasket(dataBasketarticleBack, formatedBasket)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <header className={styles.navbar}>
                    <div className={styles.overlayBasket} onClick={closeOverlayAndBasket}></div>
                    <div className={styles.basketArticle} style={!isMobile ? { top: `75px` } : {}}>
                        {(!cookies.get('basketQuantity') || totalBasket === 0) ?
                            <>
                                <div>Hum, ton panier est vide !</div>
                                <span>Remplis-le un peu...</span>
                                <img alt="" src={mascottes} />
                            </>
                            :
                            (!dataBasketarticleCookie?.added || !dataBasketarticleCookie?.added?.length === 0) ?
                                <div className={styles.loader}><div></div></div>
                                :
                                <>
                                    <div className={styles.containerArticle}>
                                        {dataBasketarticleCookie?.added?.map((item, index) => {
                                            return showArticleBasket(item, index);
                                        })
                                        }
                                    </div>
                                    <div className={styles.containerButton}>
                                        <a className={styles.button} href={'/' + (props.connected ? '' : 'validate') + 'basket'}>Voir mon panier</a>
                                        <a className={`${styles.buttonRevers} ${styles.button}`} href={'/' + (props.connected ? '' : 'validate') + 'basket'}>Passer commande</a>
                                    </div>
                                </>
                        }

                    </div>
                    <div className={styles.logoContainer}>
                        <a href={process.env.REACT_APP_SHOP_URL}><img src={logo} alt="logo" className={styles.logo} /></a>
                    </div>

                    <div className={styles.leftBtnContainer}>
                        <button
                            className={styles.menu}
                            onClick={() => {
                                if (!showMenu) {
                                    setShowMenuConnected(false)
                                }
                                setShowMenu(!showMenu)
                            }}
                        >
                            <FontAwesomeIcon icon={showMenu ? faTimes : faBars} color={"black"} />
                        </button>

                        {props.connected ?
                            <div style={{ display: "flex", alignItems: "center", gap: "0.15rem" }}>
                                <button
                                    className={`${styles.mobileUserBtn} ${styles.mobileUserBtnMarginLeft}`}
                                    onClick={() => {
                                        if (!showMenuConnected) {
                                            setShowMenu(false)
                                        }
                                        setShowMenuConnected(!showMenuConnected)
                                    }}
                                    style={{ fontSize: "2.2rem" }}
                                >
                                    <div className={styles.profileBtn}>
                                        <FontAwesomeIcon className={styles.iconUser} icon={faUser} />
                                    </div>
                                </button>


                                {disabledBasketHover ?
                                    <a
                                        className={styles.mobileUserBtn}
                                        href={'/basket'}
                                        style={{ fontSize: "1.8rem" }}
                                    >
                                        <div style={{
                                            fontSize: "0.3em",
                                            position: "absolute",
                                            right: "-4px",
                                            top: `${totalBasket > 0 ? "-1px" : "-100px"}`,
                                            backgroundColor: `${totalBasket > 0 ? "#571088" : "transparent"}`,
                                            color: "white",
                                            height: "19px",
                                            width: "19px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "20px",
                                            zIndex: 100,
                                            fontWeight: 500
                                        }}>
                                            {props.empty ? <FontAwesomeIcon className={styles.iconLoad} icon={faRotateRight} /> : totalBasket}
                                        </div>
                                        <div className={styles.basketShopping}></div>
                                    </a>
                                    :
                                    <div className={styles.mobileUserBtn} onClick={ClickMobileBasket} style={{ position: "relative", fontSize: "1.8rem" }}>
                                        <div style={{
                                            fontSize: "0.3em",
                                            position: "absolute",
                                            right: "-4px",
                                            top: `${totalBasket > 0 ? "-1px" : "-100px"}`,
                                            backgroundColor: `${totalBasket > 0 ? "#571088" : "transparent"}`,
                                            color: "white",
                                            height: "19px",
                                            width: "19px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "20px",
                                            zIndex: 100,
                                            fontWeight: 500
                                        }}>
                                            {props.empty ? <FontAwesomeIcon className={styles.iconLoad} icon={faRotateRight} /> : totalBasket}
                                        </div>
                                        <div className={styles.basketShopping}></div>
                                    </div>
                                }


                            </div>
                            :
                            props.empty ?
                                <div />
                                :
                                <div style={{ display: "flex", alignItems: "center", gap: "0.15rem" }}>
                                    <a className={`${styles.mobileUserBtn} ${styles.mobileUserBtnMarginLeft}`} href="/">
                                        <div className={styles.profileBtn}>
                                            <FontAwesomeIcon className={styles.iconUser} icon={faUser} />
                                        </div>
                                    </a>
                                    <div className={styles.mobileUserBtn} onClick={ClickMobileBasket} style={{ position: "relative", fontSize: "1.8rem" }}>
                                        <div style={{
                                            fontSize: "0.3em",
                                            position: "absolute",
                                            right: "-4px",
                                            top: `${totalBasket > 0 ? "-1px" : "-100px"}`,
                                            backgroundColor: `${totalBasket > 0 ? "#571088" : "transparent"}`,
                                            color: "white",
                                            height: "19px",
                                            width: "19px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "20px",
                                            zIndex: 100,
                                            fontWeight: 500
                                        }}>
                                            {props.empty ? <FontAwesomeIcon className={styles.iconLoad} icon={faRotateRight} /> : totalBasket}
                                        </div>
                                        <div className={styles.basketShopping}></div>
                                    </div>
                                </div>
                        }


                    </div>

                </header>

                {showMenu &&
                    <div className={styles.dropdownMenu}>
                        <div className={styles.mobileMenuItem}>
                            <a className={styles.link} href={process.env.REACT_APP_SHOP_URL}>
                                Boutique
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.link} href={process.env.REACT_APP_HELP_URL}>
                                Besoin d'aide
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.link} href={process.env.REACT_APP_SHOP_URL + "/contact"}>
                                Contact
                            </a>
                        </div>
                    </div>
                }

                {props.connected && showMenuConnected &&

                    <div className={styles.mobileDropdownMenuConnected}>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/">
                                Mon espace client
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/commands">
                                Mes commandes
                            </a>
                        </div>

                        {props.user.hasCommitment &&
                            <div className={styles.mobileMenuItem}>
                                <a className={styles.mobileLink} href="/commitment">
                                    Mes abonnements
                                </a>
                            </div>
                        }

                        {props.user.hasBox &&
                            <div className={styles.mobileMenuItem}>
                                <a className={styles.mobileLink} href="/box">
                                    Mes box et tutos
                                </a>
                            </div>
                        }

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/stats">
                                Récompenses
                            </a>
                        </div>

                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/logout">
                                Déconnexion
                            </a>
                        </div>

                    </div>
                }

            </div>

        )
    }
}

export default Header;
