import React from "react"
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../Header_Footer/FilAriane";
import { connect } from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardBox from "./CardBox";
import styles from "./Box.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import ato from "../../assets/mascottes/ato_mail.svg";
import rika from "../../assets/mascottes/rika_mail.svg";

class Box extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            boxes: undefined
        }
        this.loading = true;
    }


    doesMustLog = () => {
        this.mustLog = 1
    }

    getBoxes() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/box', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ boxes: data });
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayUserBoxes = (nbBox) => {
        let userBoxes = [];
        let displayActivate = true;
        for (let i in this.state.boxes) {
            userBoxes.push(<h2 style={{ alignSelf: "flex-start" }} id={"user" + this.state.boxes[i].id}>{this.state.boxes[i].user}</h2>)
            userBoxes.push(<div className={styles.cardContainer} style={{ flexWrap: window.innerWidth > 960 ? "wrap" : undefined, margin: "auto -10px" }}>{this.displayBox(i, nbBox)}</div>)
            userBoxes.push(<br />)
            if (displayActivate)
            {
                userBoxes.push(
                    <div className={styles.childCard}>
                    <div
                        className={globalStyles.card}
                        style={{
                            padding: "30px",
                            backgroundColor: "white",
                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                            borderRadius: "5px",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 0,
                            overflow: 'hidden',
                            position: 'relative'
                        }}
                    >
                        <h2 style={{textAlign: "center"}}>
                            Tu ne vois pas ta box ou ton tuto sur cette page ?
                        </h2>

                        <p style={{textAlign: "center", margin: "0 15% 20px"}}>
                            Le plus simple est de scanner via l'app Atorika le QR Code présent dans ta box.
                            Mais si tu n'as pas accès à l'app Atorika, tu peux activer ta box et les tutos ici.
                        </p>

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}}>
                            <a className={globalStyles.defaultBtn}  href={"/activation"}>
                                Activer
                            </a>
                        </div>
                        {window.innerWidth > 960 &&
                        <>
                            <img src={rika} alt="Rika" style={{width: '120px', position: 'absolute', bottom: '-90px', left: '6%'}}/>
                            <img src={ato} alt="Ato" style={{width: '120px', position: 'absolute', bottom: '-10px', right: '5%'}}/>
                        </>
                        }  
                    </div>
                </div>
                );
                displayActivate = false;
            }
        }
        return userBoxes;
    }


    displayBox = (index, nbBox) => {
        let boxes = [];

        if (this.state.boxes[index].activities.length > 0) {
            let activities = [];
            // activities.push(
            //     <span style={{ fontSize: "1rem", textAlign: "right", margin: "2px 0 5px", fontWeight: 500 }}>
                    
            //     </span>
            // )
            for (let i = 0; i < this.state.boxes[index].activities.length; i++) {
                activities.push(
                    <a
                        className={globalStyles.defaultLink}
                        href={process.env.REACT_APP_APP_URL + "/activity/find/" + this.state.boxes[index].activities[i].uuid}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        style={{ fontSize: "1rem", textAlign: "left", margin: "2px 0" }}
                    >
                        {this.state.boxes[index].activities[i].name}
                    </a>
                )
            }
            boxes.push(
                <div className={globalStyles.childCard} style={{ margin: 0 }}>
                    <div
                        className={globalStyles.card}
                        style={{
                            padding: "30px",
                            flex: 1,
                            backgroundColor: "#f3f6f8",
                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                            borderRadius: "5px",
                            display: "flex",
                            height: "100%",
                            boxSizing: "border-box",
                            flexDirection: "column"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: window.innerWidth > 565 ? "row" : "column" }}>
                            <div style={{ flex: 2 }}>
                                <div>
                                    <h3 style={{ margin: "0 0 15px 0" }}>
                                        Tutos DIY <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "5px" }} />
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start"
                            }}>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3,1fr)", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "left", marginTop: "10px" }}>
                                {activities}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        for (let c in this.state.boxes[index].box) {
            let links = [];
            if (this.state.boxes[index].box[c].activities) {
                for (let i = 0; i < this.state.boxes[index].box[c].activities.length; i++) {
                    links.push([
                        this.state.boxes[index].box[c].activities[i].name,
                        process.env.REACT_APP_APP_URL + "/activity/find/" + this.state.boxes[index].box[c].activities[i].uuid
                    ])
                }
            }
            boxes.push(
                <div className={globalStyles.childCard} style={{ margin: 0 }}>
                    <CardBox
                        media={this.state.boxes[index].box[c].media[0]}
                        title={this.state.boxes[index].box[c].name}
                        theme={this.state.boxes[index].box[c].theme}
                        secondaryTheme={this.state.boxes[index].box[c].secondaryTheme}
                        themeColor={this.state.boxes[index].box[c].themeColor}
                        themeBaseColor={this.state.boxes[index].box[c].themeBaseColor}
                        secondaryThemeColor={this.state.boxes[index].box[c].secondaryThemeColor}
                        secondaryThemeBaseColor={this.state.boxes[index].box[c].secondaryThemeBaseColor}
                        infos={[
                            this.state.boxes[index].box[c].description
                        ]}
                        links={links}
                    />
                </div>
            )
        }

        

        return boxes
    }

    renderChild() {

        return (
            <div>
                <Bandeau title={titleAriane["box"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["box"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["box"]["ariane"][1],
                            "/box"
                        ]
                    ]}
                />

                {this.loading ?
                    <>
                        {this.getBoxes()}
                        <div className="loader"><div></div></div>
                    </> :
                    <div className={globalStyles.globalContainer} style={{ flexDirection: "column", padding: window.innerWidth > 960 ? "20px 0 0 0" : "10px" }}>                        
                        {this.displayUserBoxes(window.innerWidth > 1020 ? 2 : 1)}
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(Box);
