import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import moment from "moment";
import {encode} from "../../Services/ObfuscatorService";
import ThemeBadge from "../Utils/ThemeBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

function CardBox(props) {

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            if(infos[i]){
                informations.push(
                    <div style={{marginBottom: "5px"}}>
                        {infos[i]}
                    </div>
                )
            }
        }
        return informations
    }

    const displayLink = (links) => {
        let url = []
        if(links.length > 1){
            url.push(
                <span style={{fontSize: "1rem", textAlign: "right", margin: "2px 0 5px", fontWeight: 500}}>
                    Accéder aux tutos <FontAwesomeIcon icon={faExternalLinkAlt} style={{marginLeft: "5px"}}/>
                </span>)
            for(let i in links){
                if(links[i]){
                    url.push(
                        <a
                            className={globalStyles.defaultLink}
                            href={links[i][1]}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            style={{fontSize: "1rem", textAlign: "right", margin: "2px 0"}}
                        >
                            {links[i][0]}
                        </a>
                    )
                }
            }
        } else {
            if(links[0]){
                url.push(
                    <a
                        className={globalStyles.defaultLink}
                        href={links[0][1]}
                        target={"_blank"} rel={"noopener noreferrer"}
                        style={{fontSize: "1rem", textAlign: "right", margin: "2px 0"}}
                    >
                        Accéder au tuto <FontAwesomeIcon icon={faExternalLinkAlt} style={{marginLeft: "5px"}}/>
                    </a>
                )
            }
        }

        return url
    }

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{display: "flex", flexDirection: window.innerWidth > 565 ? "row" : "column"}}>
                {props.media &&
                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            alt=""
                            src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.media}
                            style={{
                                maxWidth: "100%", maxHeight: "100%",
                                borderRadius: "8px",
                                width: window.innerWidth < 565 ? "calc(100% - 60px)" : undefined,
                                padding: window.innerWidth < 565 ? "20px" : undefined
                            }}
                        />
                    </div>
                }

                <div style={{flex: 2, marginLeft: props.media ? "10px" : "none"}}>
                    <div>
                        <h3 style={{margin: "0 0 15px 0"}}>
                            {props.title}
                        </h3>

                        <ThemeBadge
                            theme={props.theme}
                            baseColor={props.themeBaseColor}
                            bgColor={props.themeColor}
                        />

                        {props.secondaryTheme &&
                            <ThemeBadge
                                theme={props.secondaryTheme}
                                baseColor={props.secondaryThemeBaseColor}
                                bgColor={props.secondaryThemeColor}
                                style={{marginLeft: "5px"}}
                            />
                        }


                        <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                            {props.date &&
                                <div style={{marginBottom: "5px"}}>
                                    {(new Date(props.date) >= new Date()) &&
                                        "A choisir avant le "
                                    }
                                    {moment(props.date).format("DD/MM/YYYY")}
                                </div>
                            }

                            {displayInfos(props.infos)}
                        </div>
                    </div>

                    {(new Date(props.date) >= new Date()) &&
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                textAlign: "left",
                                marginTop: "10px"
                            }}>
                                <a className={globalStyles.defaultLink} href={"/commitment/choose/" + encode(props.order)}>
                                    Changer
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>


            {props.links &&
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                        {displayLink(props.links)}
                    </div>
                </div>
            }

        </div>
    );
}

export default CardBox;