import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faUser} from "@fortawesome/free-solid-svg-icons";
import LoggedComponent, {mapStateToProps} from "../../../Services/ConnexionService";
import FilAriane from "../../Header_Footer/FilAriane";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from '../../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import Bandeau from "../../Header_Footer/Bandeau";
import styles from "./Code.module.css";
import titleAriane from "../../../Helpers/TitleAriane.json";
import ThemeBadge from "../../Utils/ThemeBadge";


class ActivationCode extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            // code: '',
            creator: 'Alban',
            theme:null,
            themes:null,
            reference: '',
            userFor: null,
            error: false,
            errorMsg : ''
        };
        this.users = [];
        this.loading = true;
        this.fetching = false;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getThemes(){
        if (this.loading && !this.fetching)
        {
            this.fetching = true;
            let request = new Request(process.env.REACT_APP_API_URL + '/themes', {method:'GET', credentials:'include'});
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                this.setState({themes: data.themes,theme: data.themes[0]});
                this.loading = false;
                this.fetching = false;
                this.forceUpdate();
            })
        }
    }

    submit = () => {
        let data = {
            reference: this.state.reference,
            // code : this.state.code,
            theme: this.state.theme.id,
            creator : this.state.creator,
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/box/activation/' + this.state.user.id, {method:'POST', body: jsonD, credentials:'include'})
        this.fetchJsonOrError(request, (data) => {
            switch (data.type) {
                case 'OK':
                    this.addFlash('success', 'La box a bien été activée !')
                    this.setState({shouldNavigate: true, navigateTo:'/box'})
                    break
                case 'NOBOX':
                    this.setState({...this.state, error: true, errorMsg : "Nous n'avons pas trouvé cette box."})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : "Impossible d'activer la box avec ces informations."})
            }
        })
    }

    renderChild () {
        return (
            <div>
                <Bandeau title={titleAriane["activateBox"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["activateBox"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["activateBox"]["ariane"][1],
                            "/box"
                        ],
                        [
                            titleAriane["activateBox"]["ariane"][2],
                            ""
                        ],
                    ]}
                />
                {this.loading ? (
                    <>
                        {this.getThemes()}
                        <div className="loader"><div></div></div>
                    </>)
                    :
                    <div className={globalStyles.globalContainer}>
                        <div style={{flex: 1}}>

                            <div style={{margin: window.innerWidth < 1020 ? "0 10px" : undefined}}>
                                <h2>Tu as acheté une box hors ligne ou tu l'as reçue en cadeau ?</h2>

                                Pour accéder aux tutos liés à cette box, tu dois tout d'abord l'activer.<br/>
                                Pour cela, remplis les informations ci-dessous.
                            </div>

                            <div className={formStyles.inputLine} style={{margin: "20px 0"}}>
                                <div className={formStyles.inputGroup} style={{flex: 1}}>
                                    <label>Référence de la box*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon} style={{flex: 2}}>
                                            <FontAwesomeIcon icon={faBox}/>
                                        </div>
                                        <input
                                            type={"text"}
                                            required
                                            className={formStyles.input}
                                            placeholder="Exemple : B000001"
                                            value={this.state.reference}
                                            onChange={(event) => {
                                                this.setState({reference: event.target.value});
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={formStyles.inputGroup} style={{flex: 2}}>
                                    <label>Thème principal de la box*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon} style={{flex: 2}}>
                                                <ThemeBadge
                                                    theme={this.state.theme.name}
                                                    baseColor={this.state.theme.baseColor}
                                                    bgColor={this.state.theme.color}
                                                />
                                        </div>
                                        <select 
                                            value={JSON.stringify(this.state.theme)} 
                                            className={formStyles.input} 
                                            onChange={(event) => this.setState({theme: JSON.parse(event.target.value)})}
                                            style={{
                                                flex: 12,
                                                border: "none",
                                                outline: "none"
                                            }}
                                            >
                                            {
                                                
                                                this.state.themes?.map(theme =>
                                                    <option value={JSON.stringify(theme)}>
                                                        {theme.group + ' - ' + theme.name}
                                                    </option>
                                                    
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={formStyles.inputGroup} style={{flex: 2}}>
                                    <label>Box créée par*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon} style={{flex: 2}}>
                                            <FontAwesomeIcon icon={faUser}/>
                                        </div>
                                        <select
                                            value={this.state.creator}
                                            defaultValue=""
                                            onChange={(event) => {
                                                this.setState({creator: event.target.value});
                                            }}
                                            style={{
                                                flex: 12,
                                                border: "none",
                                                outline: "none"
                                            }}
                                        >
                                            <option value="Alban">Alban</option>
                                            <option value="Aurélien">Aurélien</option>
                                            <option value="Caroline">Caroline</option>
                                            <option value="Céline">Céline</option>
                                            <option value="Charlie">Charlie</option>
                                            <option value="Christophe">Christophe</option>
                                            <option value="David">David</option>
                                            <option value="Emilie">Emilie</option>
                                            <option value="Emma">Emma</option>                                        
                                            <option value="Isabelle">Isabelle</option>
                                            <option value="Julien">Julien</option>
                                            <option value="Laurent">Laurent</option>
                                            <option value="Léa">Léa</option>
                                            <option value="Louise">Louise</option>
                                            <option value="Lucas">Lucas</option>
                                            <option value="Nathalie">Nathalie</option>
                                            <option value="Nicolas">Nicolas</option>
                                            <option value="Sophie">Sophie</option>
                                            <option value="Stéphanie">Stéphanie</option>
                                            <option value="Yoan">Yoan</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className={formStyles.inputGroup}>
                                    <label>Code d'activation*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon} style={{flex: 2}}>
                                            <FontAwesomeIcon icon={faLock}/>
                                        </div>
                                        <input
                                            type={"text"}
                                            required
                                            className={formStyles.input}
                                            placeholder="Code d'activation"
                                            value={this.state.code}
                                            onChange={(event) => {
                                                this.setState({code: event.target.value.toUpperCase()});
                                            }}
                                        />
                                    </div>
                                </div> */}
                            </div>

                            <div style={{textAlign: "right", marginRight: window.innerWidth < 1020 ? "10px" : undefined}}>
                                <a
                                    className={globalStyles.defaultLink}
                                    href={process.env.REACT_APP_HELP_URL + "/article/ou-trouver-les-informations-pour-activer-une-box"}
                                    target={"_blank"} rel={"noopener noreferrer"}
                                >Où trouver ces informations ? </a>
                            </div>

                            {this.state.error &&
                                <div className={styles.error}>
                                    {this.state.errorMsg}
                                </div>
                            }

                            <div className={formStyles.btnContainer} style={{marginTop: "30px"}}>
                                <button type="button" className={globalStyles.defaultBtn} onClick={this.submit}>
                                    Activer
                                </button>
                            </div>


                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(ActivationCode);
